.bb-gray {
  border-bottom: 1px solid #dddddd;
}

.bt-gray {
  border-top: 1px solid #dddddd;
}

.drop-shadow {
  box-shadow: 0 2px 6px #c1c1c8;
}

.corner-8 {
  border-radius: 8px;
}