.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-60 {
  margin-top: 60px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mr-10 {
  margin-right: 10px;
}
.p-0 {
  padding: 0px;
}
.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.p-30 {
  padding: 30px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-90 {
  padding-top: 90px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ph-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.ph-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.ph-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.ph-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-0 {
  flex: 0;
}