@import './globals.scss';

// Font Size
.fs-10 { font-size: 10px; }
.fs-11 { font-size: 11px; }
.fs-12 { font-size: 12px; } 
.fs-13 { font-size: 13px; }
.fs-14 { font-size: 14px; }
.fs-15 { font-size: 15px; }
.fs-16 { font-size: 16px; }
.fs-18 { font-size: 18px; }
.fs-20 { font-size: 20px; }
.fs-24 { font-size: 24px; }
.fs-30 { font-size: 30px; }
.fs-32 { font-size: 32px; }
.fs-36 { font-size: 36px; }
.fs-40 { font-size: 40px; }
.fs-48 { font-size: 48px; }
.fs-50 { font-size: 50px; }
.fs-52 { font-size: 52px; }
.fs-60 { font-size: 60px; }

// Font Weight
.fw-100 { font-weight: 100; }
.fw-200 { font-weight: 200; }
.fw-300 { font-weight: 300; }
.fw-400 { font-weight: 400; }
.fw-500 { font-weight: 500; }
.fw-600 { font-weight: 600; }
.fw-700 { font-weight: 700; }
.fw-800 { font-weight: 800; }

// Text transform
.uppercase { text-transform: uppercase; }

// Color
.c-text { color: $text-color }
.c-text-gray { color: $text-light-gray}
.c-white { color: $white }
.c-blue { color: $blue-primary }
.c-light-blue { color: $blue-light-30 }
.c-red { color: $red }

// Alignment
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.flex-right {
  justify-content: right;
}

.line-1 { line-height: 1; }
.line-1-1 { line-height: 1.1; }
.line-1-2 { line-height: 1.2; }
