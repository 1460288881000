@mixin for-xlarge {
  @media (max-width: 1599px) {
    @content;
  }
}

@mixin for-large {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin for-medium {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin for-small {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin for-xsmall {
  @media (max-width: 575px) {
    @content;
  }
}