// Blues
$blue-primary: #4871f7;
$blue-primary-hover: #4166de;

$blue-light-10: #5a7ff8;
$blue-light-20: #6d8df9;
$blue-light-30: #7f9cf9;
$blue-light-40: #91aafa;
$blue-light-50: #a4b8fb;
$blue-light-60: #b6c6fc;
$blue-light-70: #c8d4fd;
$blue-light-80: #dae3fd;
$blue-light-90: #edf1fe;

$blue-dark-10: #4166de;
$blue-dark-20: #3a5ac6;
$blue-dark-30: #324fad;
$blue-dark-40: #2b4494;
$blue-dark-50: #24397c;
$blue-dark-60: #1d2d63;
$blue-dark-70: #16224a;
$blue-dark-80: #0e1731;
$blue-dark-90: #070b19;

// White
$white: #FFFFFF;

// Black
$black: #000000;
$black1: #101010;
$black2: #202020;
$black3: #303030;
$black4: #404040;
$black5: #505050;
$black6: #606060;
$black7: #707070;
$black8: #808080;
$black9: #909090;

// Gray
$gray1: #F2F2F2;
$gray3: #F3F3F3;
$gray5: #F5F5F5;
$gray7: #F7F7F7;
$gray9: #F9F9F9;

$red: #e74c3c;

$button-hover: #4877DA;

// Text
$text-color: #424851;
$text-light-gray: #777777;