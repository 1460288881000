@import './styles/globals.scss';
@import 'styles/buttons.scss';
@import 'styles/whitespace.scss';
@import 'styles/borders.scss';
@import 'styles/backgrounds.scss';
@import 'styles/font.scss';

// Global
body {
  margin: 0;
  // font-family: 'Montserrat', sans-serif;
  // font-family: Graphik,Helvetica,Arial,sans-serif;
  // font-family: 'Open Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
  // font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

h1 {
  font-size: 32px;
  font-weight: 800;
}

h2 {
  font-size: 18px;
  font-weight: 700;
}

.cursor-default {
  cursor: default; 
}

.menu-icon {
  display: none;
  font-size: 30px;
  float: right;
  color: #909090;

  button {
    height: 64px;
    width: 64px;
    background-color: transparent;
    border: none;
    outline: none;
  }

  @include for-small {
    display: inline-block;
  }
}

.site-nav {
  float: right;
  height: 80px;
  display: flex;
  cursor: default;

  &--item {
    display: flex;
    align-items: center;
    flex:1;
    font-size: 14px;
    padding: 0 15px;
    text-align: center;
    color: $black7;
    font-weight: 500;
    transition: color .5s, background-color .5s;
    border-radius: 8px;
    margin: 12px 2px;

    &:hover {
      color: $black3;
      background-color: #f9f9f9;
    }

    &.item-selected {
      color: $blue-primary;
    }
  }

  @include for-small {
    display: none;
  }
}

.site-nav-mobile {
  &--close {
    padding: 24px;
    text-align: right;
    color: $black9;
    font-size: 24px;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item {
    padding: 30px 16px;
  }
  .ant-menu-inline .ant-menu-submenu {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    text-align: center;
    font-size: 24px;
    color: #909090;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    margin-left: 10px;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $gray7;
  }
  .ant-menu-inline .ant-menu-item::after {
    background-color: $gray7;
  }
}

// Site Layout
.site-layout {
  min-height: 100vh;
  background-color: $gray7;
  
  .site-header {
    background: $white;
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, .3);
    padding: 0px 30px;
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 80px;
    line-height: 80px;

    @include for-small {
      padding-right: 10px;
    }
  }

  .site-content {
    background-color: $gray7;
    padding: 0;
    margin-top: 80px;
  }

  .site-footer {
    padding: 0;
    color: $white;

    @include for-small {
      text-align: center;
    }

    a {
      color: $blue-light-70;
      font-size: 16px;
      line-height: 1.6;
      &:hover {
        color: $blue-light-90;
      }
    }
  }
}

.ant-dropdown-menu-item {
  padding: 10px 15px;
}

// Sections
.section-container {
  display: flex;
  justify-content: center;

  &--inner {
    width: 100%;
    max-width: 1200px;

    @media (max-width: 1200px) {
      padding: 0 30px;
    }

    @include for-small {
      padding: 0 24px;
    }
  }
}

// Containers
.floating-container {
  display: flex;
  justify-content: center;

  &--inner {
    width: 100%;
  }
}

.tile {
  cursor: default;
  height: 150px;
  box-shadow: 0 2px 6px #c1c1c8;
  transition: box-shadow 0.5s;

  &:hover {
    box-shadow: 0 4px 12px #c1c1c8;
  }
}

.tile-icon {
  padding-top: 20px;
  text-align: center;
  span {
    font-size: 50px;
    color: #939aef;
  }
}

.tile-text {
  text-align: center;
  padding: 20px;
  font-weight: 700;
}

.page-content {
  min-height: calc(100vh - 134px);
}

.page-header {

  &--title {
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: $white;
    line-height: 1.1;

    @include for-small {
      font-size: 40px;
    }
  }

  &--breadcrumb {
    // text-align: center;
    cursor: default;
    color: $white;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 500;
  
    > a {
      color: $white;
  
      &:hover {
        color: $gray7;
      }
    }
  }

  &--box {
    @include shadow-box;
    padding: 60px 30px;
    text-align: center;
    margin-top: -90px;
    @include for-small {
      padding: 40px 20px;
    }
  }

  &--box-title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.1;
    @include for-small {
      font-size: 24px;
    }
  }

  &--box-text {
    font-size: 18px;
    color: $text-light-gray;
    @include for-small {
      font-size: 16px;
    }
  }
}

.form-label {
  font-weight: 600;
  margin-left: 6px;
  margin-bottom: 2px;
  color: #9b9b9b;
  font-size: 14px;
}

.medium-button {
  background-color: $blue-primary;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  padding: 12px 20px;
  transition: background-color .4s, color .4s;
  border-radius: 4px;
  border: 0;
  &:hover {
    cursor: default;
    background-color: $blue-primary-hover;
  }
  &:focus {
    outline: none
  }
}

.gray-header {
  background-color: #f7f7f7;
  padding: 40px 0;
}

.net-worth-page {
  .ant-form-item {
      margin-bottom: 0px;
  }
}

.section-header {
  text-align: center;

  .section-title {
    font-size: 32px;
    font-weight: 800;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 40px;
    text-transform: uppercase;
    @include for-small {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }
  
  .section-divider {
    display: inline-block;
    border-bottom: 4px solid $blue-primary;
    width: 60px;
    margin-bottom: 40px;
    @include for-small {
      width: 40px;
      margin-bottom: 20px;
    }
  }

  .section-subtitle {
    font-size: 18px;
    font-weight: 400;
    color: $text-light-gray;
  }
}

.investment-counter {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
}

.investment-box {
  background-color: #fafafa;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;

  .ant-form-item {
    margin-bottom: 0;
  }
}

.body-link {
  display: inline-block;
  cursor: default;
  color: #004df3;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  padding: 2px 8px;
}

.body-link-button {
  display: inline-block;
  cursor: default;
  color: #004df3;
  margin-bottom: 12px;
  font-weight: 700;
  border: 1px solid $blue-primary;
  padding: 2px 8px;
}

.remove-link {
  display: inline-block;
  cursor: default;
  font-weight: 700;
  color: #ff7777;
  border: 1px solid #ff7777;
  padding: 2px 8px;
}

.app-container {
  height: 100vh;

  .menu-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
  }

  .content-container {
    margin-top: 60px;
  }
}

.home-hero-container {
  background-color: $white;
  padding: 60px;
  border-radius: 16px;
  margin-top: 60px;
  margin-bottom: -150px;
  box-shadow: 0 2px 6px #c1c1c8;

  @include for-large {
    padding: 30px;
  }

  @include for-small {
    padding: 30px;
  }
}

.home-hero {

  @include for-small {
    text-align: center;
  }

  .home-hero-box {
    display: flex;
    align-items: center;
  }

  .home-image {
    box-shadow: 0 2px 6px #c1c1c8;
  }

  .home-above-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: 1px;
    color: $text-light-gray;
  }

  .home-title {
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 10px;

    @include for-large {
      font-size: 50px;
      line-height: 50px;
    }

    @include for-medium {
      font-size: 42px;
      line-height: 42px;
    }

    @include for-small {
      font-size: 30px;
      line-height: 30px;
    }
  }

  .home-subtitle {
    font-size: 16px;
    font-weight: 400;
    color: $text-light-gray;
    line-height: 1.5;
    margin-top: 20px;
  }

  .home-subtitle-2 {
    font-size:15px;
    color: #555;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-right: 20px;
  }
}

.home-section-title {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1em;
}

.home-section-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #777777;
  margin-bottom: 60px;
  text-align: center;
}

.button-shadow-box {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  transition: box-shadow 0.5s;
  // box-shadow: 0 1px 4px #c1c1c8;
  height: 100%;

  &--icon {
    color: #e9e9e9;
    font-size: 60px;
    transition: color 0.5s;
    line-height: 1;
    margin-bottom: 15px;
  }

  // &--title {
  //   font-size: 24px;
  //   font-weight: 700;
  //   color: #222222;
  // }

  &--subtitle {
    font-size: 16px;
    line-height: 1.6em;
    margin-top: 10px;
    color: #777777;
  }

  &:hover {
    cursor: default;
    box-shadow: 0 4px 12px #c1c1c8;

    .button-shadow-box--icon {
      color: $blue-light-20;
    }
  }

  .home-button {
    background-color: $blue-primary;
    color: #fff;
    font-weight: 700;
    display: inline-block;
    padding: 8px 16px;
    transition: background-color .4s, color .4s;
    border-radius: 4px;
    border: 0;
    &:hover {
      cursor: default;
      background-color: $blue-primary-hover;
    }
    &:focus {
      outline: none
    }
  }
}

.button-shadow-box-picture {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  transition: box-shadow 0.5s;
  box-shadow: 0 1px 4px #c1c1c8;

  &--image {
    img {
      width: 100%;
    }
  }

  &--title {
    font-size: 30px;
    line-height: 1;
    font-weight: 800;
  }

  &--subtitle {
    font-size: 16px;
    line-height: 1.6em;
    margin-top: 10px;
    color: #777777;
  }

  &:hover {
    cursor: default;
    box-shadow: 0 4px 12px #c1c1c8;
  }
}

.page-section {
  &--image {
    width: 100%;
    object-fit: cover;
    box-shadow: 20px 20px 0px #f5f5f5;
    @include for-small {
      box-shadow: none;
    }
  }
}

.page-section-header {
  padding: 40px 0;
  text-align: center;

  .page-section-title {
    font-size: 40px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 48px;
  }

  .page-section-subtitle {
    font-size: 18px;
    font-weight: 300;
    margin-top: 20px;
  }

  .page-section-divider {
    display: inline-block;
    height: 4px;
    width: 100px;
    background-color: $blue-primary;
    background-color: $blue-light-40;
    margin-top: 20px;
  }
}

.page-section-content {
  margin-left: 30px;

  &--title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.1;
  }

  @include for-small {
    margin-left: 0;
    margin-top: 20px;

    &--title {
      font-size: 30px;
    }
  }
}

.philosophy-section {
  @include for-small {
    text-align: center;
  }
}

.finance-section-icon {
  color: $blue-light-70;
  font-size: 50px;
  line-height: 0;
  @include for-small {
    font-size: 80px;
  }
}

.finance-section-title {
  color: $blue-primary;
  font-size: 24px;
  font-weight: 700;
}

.finance-section-text {
  font-size: 16px;
  margin-top: 6px;
  color: #777777;
}

.form-section-header {
  font-weight: 800;
  font-size: 30px;
}

.form-section-desc {
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 8px;
}

.page-button {
  font-size: 16px;
  background-color: $blue-primary;
  color: $white;
  font-weight: 600;
  display: inline-block;
  padding: 14px 30px;
  transition: background-color .4s, color .4s;
  border-radius: 4px;
  border: 0;
  &:hover {
    cursor: default;
    background-color: $blue-primary-hover;
  }
  &:focus {
    outline: none
  }
}

.small-page-button {
  font-size: 14px;
  background-color: $blue-primary;
  color: $white;
  font-weight: 600;
  display: inline-block;
  padding: 3px 16px;
  transition: background-color .4s, color .4s;
  border-radius: 4px;
  border: 1px solid $blue-primary;
  &:hover {
    cursor: default;
    background-color: $blue-primary-hover;
  }
  &:focus {
    outline: none
  }
}

.small-page-button-outline {
  font-size: 14px;
  background-color: transparent;
  color: $blue-primary;
  font-weight: 600;
  display: inline-block;
  padding: 3px 16px;
  transition: background-color .4s, color .4s;
  border-radius: 4px;
  border: 1px solid $blue-primary;
  &:hover {
    cursor: default;
    background-color: $blue-light-90;
  }
  &:focus {
    outline: none
  }
}

.verse-box {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 2px 6px #c1c1c8;

  &--reference {
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    padding: 30px 30px 20px 30px;
  }

  &--text {
    font-weight: 500;
    font-size: 16px;
    color: #555;
    padding: 0 30px 30px 30px;
  }

  &--collapse {
    margin: 0 10px 10px 10px;
    border-radius: 8px;
    padding: 20px 20px;
    background-color: $gray7;
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0;
      font-weight: 700;
      font-size: 14px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      margin-top: 10px;
      border-radius: 4px;
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.shadow-box {
  @include shadow-box;
}

.loading-spinner {
  font-size: 30px;
  text-align: center;
  padding: 60px 0;
}

.about-me-page {
  .ant-timeline-item-head {
    background-color: transparent;
  }
  .ant-timeline-item-content {
    line-height: 1.5;
    padding-left: 10px;
  }
}

.bookkeeping-section {
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
    padding: 0;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #fbfbfb;
  }

  .top-cell {
    padding: 8px 16px;
    min-height: 38px;
    display: flex;
    align-items: center;
  }

  .bottom-cell {
    padding: 8px 16px;
    min-height: 38px;
    background-color: $gray9;
    transition: background-color 0.5s;
    display: flex;
    align-items: center;
  }

  .top-cell-edit {
    padding: 8px 16px;
    min-height: 50px;
    display: flex;
    align-items: center;
  }

  .bottom-cell-edit {
    padding: 8px 16px;
    min-height: 50px;
    background-color: $gray9;
    display: flex;
    align-items: center;
  }

}

.ant-tag-checkable {
  font-size: 14px;
  padding: 4px 10px;
  margin-right: 10px;
  font-weight: 500;
  color: $blue-primary;
}

.ant-tag-checkable-checked {
  background-color: $blue-primary;
  color: $white;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: $blue-primary;
}

.skills-tag {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  background-color: $gray7;
  padding: 4px 10px;
  margin: 8px 8px;
  border-radius: 4px;
}

.blog-post-card {
  cursor: default;
  &--image {
    width: 100%;
  }
  &--title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.1;
    margin-top: 10px;
    @include for-small {
      font-size: 24px;
    }
  }
  &--description {
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    color: $text-light-gray;
  }
}

.blog-post-page {
  line-height: 1.1;

  .blog-post-back {
    font-weight: 700;
    font-size: 15px;
    // color: $blue-primary;
    a {
      color: $text-color;
    }
  }

  .blog-post-name {
    font-size: 50px;
    font-weight: 700;

    @include for-small {
      font-size: 30px;
    }
  }

  .blog-post-content {
    h1 {
      color: $text-color;
      font-size: 40px;
      @include for-small {
        font-size: 30px;
      }
    }
  
    h2 {
      color: $text-color;
      font-size: 30px;
      @include for-small {
        font-size: 24px;
      }
    }
  
    p {
      font-size: 16px;
      line-height: 1.5;
    }
  
    img {
      max-height: 500px;
  
      @include for-small {
        max-height: none;
        width: 100%;
      }
    }
  
    ul {
      li {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
}

.mortgage-calculator-page {
  .calculator-section-header {
    font-size: 32px;
    font-weight: 700;
    @include for-small {
      font-size: 24px;
      line-height: 1.2;
    }
  }
  .result-amount {
    font-size: 32px;
    font-weight: 700;
    @include for-small {
      font-size: 24px;
    }
  }
  .ant-picker-large {
    input {
      height: 35px;
      padding: 0 4px;
    }
  }

  .ant-input-number-lg {
    input {
      height: 50px;
      padding: 0 15px;
    }
  }
}

.pagebreak {
  page-break-inside: avoid;
}

.sortable-row-container {
  cursor: default;
  list-style: none;
  padding: 0;
}

.sortable-row-dragging {
  list-style: none;
  background-color: $white;
  border-top: 1px solid #dddddd;
}

.timeline-editor-page {
  .ant-form-item {
    margin: 0;
  }
}

.admin-header {
  background-color: $white;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, .3);
  z-index: 1;
}

.admin-layout {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $gray7;
  }
  .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
    color: $blue-primary;
  }
  .ant-menu-item-selected {
    color: $blue-primary;
  }
  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
}

.modal-header {
  background-color: $white;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, .3);
  height: 64px;
  padding: 0 15px 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  &--title {
    font-size: 16px;
    font-weight: 500;
  }

  &--icon {
    padding-top: 6px;
    font-size: 40px;
    line-height: 1;
    color: #CCC;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.post-tag {
  cursor: default;
  background-color: $blue-primary;
  color: $white;
  padding: 6px 12px;
  display: inline-block;
  font-weight: 700;
  font-size: 11px;
  &.large {
    font-size: 14px;
    padding: 8px 16px;
    transition: background-color 0.5s;
    &:hover {
      background-color: $blue-primary-hover;
    }
  }
}

.circle-icon {
  background-color: #f7f7f7;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  text-align: center;
  font-size: 80px;
  color: $blue-light-30;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.experience-row {
  display: flex;
  align-items: center;

  @include for-small {
    flex-direction: column;
    text-align: center;
  }
  
  &--col-1 {
    flex: 0;
  }

  &--col-2 {
    flex: 1;
    margin: 0 0 0 30px;
    @include for-small {
      margin: 20px 0 0 0;
    }
  }

  &--title {
    // color: $blue-primary;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    @include for-small {
      font-size: 24px;
    }
  }
}