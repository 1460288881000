.white-outline-button {
  @include white-outline-button;
}

.black-outline-button {
  @include black-outline-button;
}

.primary-button {
  height: 48px;
  background-color: $blue-primary;
  width: 100%;
  color: $white;
  border: none;
  outline: none;
  font-size: 18px;
  border-radius: 8px;
  font-weight: 600;
  transition: background-color .4s, color .4s;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &:hover {
    cursor: default;
    background-color: $blue-primary-hover;
  }
}