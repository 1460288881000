.bg-gray {
  background-color: #f7f7f7;
}

.bg-light-gray {
  background-color: #f9f9f9;
}

.bg-white {
  background-color: $white;
}

.bg-black {
  background-color: $black;
}

.bg-black5 {
  background-color: $black5;
}

.bg-blue-gradient {
  background: -webkit-linear-gradient(0deg,#4871f7 0%,#6d8df9 100%);
}
