@mixin standard-button {
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 500;
  display: inline-block;
  padding: 14px 24px;
  transition: background-color .4s, color .4s;
  border-radius: 4px;
  &:hover {
    cursor: default;
  }
  &:focus {
    outline: none
  }
}

@mixin white-outline-button {
  @include standard-button;
  border: 1px solid #fff;
  color: #fff;
  &:hover {
    background-color: #fff;
    color: #000;
  }
}

@mixin black-outline-button {
  @include standard-button;
  border: 1px solid #303030;
  color: #303030;
  &:hover {
    background-color: #303030;
    color: #fff;
  }
}